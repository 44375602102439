@charset "UTF-8";
body{font-family: SourceSerifPro; margin:0px; font-size:16px; margin:auto 0;padding-bottom: 0;-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    overflow-x:hidden;  
} 
@font-face {font-family:SourceSerifPro; src:url("../fonts/SourceSerifPro-Regular.otf") format("opentype");}
@font-face {font-family:SourceSeriflight; src:url("../fonts/SourceSerifPro-Light.otf") format("opentype");}
@font-face {font-family:Montserrat; src:url("../fonts/Montserrat-Regular.otf") format("opentype");}

h1, h2, h3, h4, h5, form, img, li, ul, a, a:hover{border:0px; margin:0px; padding:0px; text-decoration:none; list-style:none;}

* {box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box;}
input[type="text"],input[type="submit"],input[type="button"],input[type="password"], select, textarea {
    -webkit-appearance:none; -moz-appearance:none; appearance:none;
        font-family: SourceSerifPro;
}


#contenor {
    width:1160px; 
    margin:auto; 
    background-color:#FFFFFF;clear: both;
}

#header {
    width: 100%;
   
    position: relative;
    clear: both;
    float: left;
    padding: 16px 15px 20px; 
}
#banner.contac{
    height: 125px;
    min-height: inherit;
    background-color: #781007;    
    display: -webkit-box;  margin-bottom: 0;  
    display: -ms-flexbox;    
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    z-index: 99;
}


#banner.contac img{display: none !important;}
#logo {
    padding: 0px 0px 0px 0px;
    float:left;
    overflow: hidden;
    width: 225px;
}
#banner.contac .heading2 {
    color: #fff;
    margin: 0;
    border-bottom:0;
}

#logo img { 
    width:100%; 
    height:auto;
}
    
.about_videos {
    float: left;
    width: 100%;
    margin-top: 30px;
}
.about_videos ul.video_list li {
    width: 32%;
}
div#g-recaptcha {
    margin-left: 100px;
} 



#navi {
    font-family: Montserrat;
    font-size: 16px;
    color: #000; 
    float:right;
    padding: 85px 0px 0px 0px;
}

#navi li {
    padding: 0px 14px 0px 7px;
    display: inline;
    border-right: 1px solid #961108;
}

#navi li:last-child {
    border-right: none;
}

#navi li a { 
    text-decoration:none; 
    color:#000;    
    position: relative;
}
#navi > ul> li> a:after{
    position: absolute;
    content: '';
    width: 0px;
    height: 2px;
    background: #961108;
    left: 0;
    bottom: -2px;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}
#navi > ul> li:hover a:after ,#navi > ul> li> a.activelink:after {
    text-decoration:none; 
    -webkit-transition: all .3s ease-in; 
    -o-transition: all .3s ease-in; 
    transition: all .3s ease-in;
    width: 100%;
}



#banner {
    width:100%; 
  overflow: hidden; clear: both;
    margin-bottom:20px;
    -webkit-box-shadow:0px 3px 3px rgba(0, 0, 0, 0.4);
            box-shadow:0px 3px 3px rgba(0, 0, 0, 0.4);
}


.flexslider {
    width: 100%;
    height: auto;
    clear: both;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.4);
}

.heading1 {
    font-family:Montserrat; 
    font-weight:normal; 
    font-size:28px; 
    margin-top:70px; 
    text-align:center;text-transform: uppercase;
    color:#961108; 
    line-height: 40px;
}

.heading2 {
    font-family:Montserrat; 
    font-weight:normal; 
    font-size:28px; 
    margin-top:50px; 
    text-align:left;
    color:#961108; 
    line-height: 40px;
    text-transform: uppercase;
}

.h_bottom_line {
    margin:auto;
    width:120px;
    text-align:center;
    border-bottom: 2px solid;
}

.contentf {
    margin-top:40px; 
    text-align: center;
    padding: 0px 50px;
    color:#303030;
}
    
.innercontent {
    text-align: center; 
    font-family:SourceSerifPro; 
    font-weight:normal; 
    font-size:20px; 
    line-height:27px; 
    color:#303030;
}   


.red_btn {    
    padding: 10px 15px !important;
    background-color: #961108;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    border-radius: 5px;
    margin-top: 40px !important;
}

.btnarrow img {
    width: 25px;
    height: auto;
    margin: 5px 0px 2px 5px;
}




/* Internal Pages CSS Start*/


.heading2 {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 28px;
    margin-top: 50px;
    text-align: left;
    color: #961108;
    line-height: 40px;
    border-bottom: #961108 2px solid;
    width: 100p;
    display: inline-block;
}

.h_bottom_line2 {
    margin:auto;
    width:100px;
    text-align:left;
    border-bottom:#961108 2px solid;
}

.contentf2 {
    margin-top:40px; 
    text-align: left;
    font-size: 18px;
    color: #303030;
}


/* Internal Pages CSS END*/


#footer {
    width:100%; 
    margin-top:70px; 
    overflow: hidden; 
    background-color:#303030;
    padding:20px 0px;
}
#footerinner {
    width: 1160px;
    overflow: hidden;
    margin: auto;
    font-family: SourceSeriflight;
    font-weight: normal;
    font-size: 14px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
#copyright {
    float:left;
    margin-top:0;
}

#footerrighttxt{
    float:right; 
}

#footerrighttxt img{
    vertical-align:middle;
}


.menu-icon {
    width: 40px;
    height: 30px;
    display: none;
    cursor: pointer;
    z-index: 999;
    position: absolute;
    top: 20px;
    right: 0;
}
.menu-icon span {
    position: relative;
    top: 13px;
    display: block;
    width: 30px;
    height: 2px;
    background: #781007;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.menu-icon span:before, .menu-icon span:after {
    width: 30px;
    height: 2px;
    background-color: #781007;
    display: block;
    position: absolute;
    content: '';
}
.menu-icon span:before {
    margin-top: -9px;
    -webkit-transition-property: margin, -webkit-transform;
    transition-property: margin, -webkit-transform;
    -o-transition-property: margin, transform;
    transition-property: margin, transform;
    transition-property: margin, transform, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0s;
    -o-transition-delay: 0.2s, 0s;
    transition-delay: 0.2s, 0s;
}
.menu-icon span:after {
    margin-top: 9px;
    -webkit-transition-property: margin, -webkit-transform;
    transition-property: margin, -webkit-transform;
    -o-transition-property: margin, transform;
    transition-property: margin, transform;
    transition-property: margin, transform, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0s;
    -o-transition-delay: 0.2s, 0s;
    transition-delay: 0.2s, 0s;
}
 .hover span {
    background: transparent;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
}

.ovrly {
    position: fixed;
    width: 100%;
    height: 100%;
    left: -150%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 99;
    top: 0;
    -webkit-transition: all .8s ease-out;
    -o-transition: all .8s ease-out;
    transition: all .8s ease-out;
}
.ovrly.open {
    left: 0;
    -webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
}
.hover span:before {
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: 0s, 0.2s;
    -o-transition-delay: 0s, 0.2s;
    transition-delay: 0s, 0.2s;
}
.hover span:after {
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition-delay: 0s, 0.2s;
    -o-transition-delay: 0s, 0.2s;
    transition-delay: 0s, 0.2s;
}
.has_sub {
    position: relative;
    padding-right: 20px !important;
}
ul.submenu {
    position: absolute;
    left: 0px;
    top: 100%;-webkit-transition: all .3s ease-out;-o-transition: all .3s ease-out;transition: all .3s ease-out;
    width: 170px;max-height: 0;overflow:hidden; 
    z-index: 9999;
    padding-top: 10px;
}
.submenu li {
    display: block !important;
    text-align: center;
    width: 100%;padding: 0 !important;
    background: #a71509;border-right: 0 !important;
}
.submenu li  a {
    padding: 12px 15px !important;
    color: #fff !important;
    display: block;
    font-size: 13px;
    border-bottom: 1px solid #820c04;
}
.submenu li a.activelink ,.submenu li a:hover{background: #3e0803; text-decoration: none;}


.submenu li:last-child a{border-bottom: 0;}
.has_sub:hover .submenu{-webkit-transition: all .3s ease-in;-o-transition: all .3s ease-in;transition: all .3s ease-in;max-height: 200px;}
.has_sub:after {
    position: absolute;
    right: 6px;
    content: '';
    border-top: 7px solid #888080;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    top: 6px;
}



/*Loader css*/

#preloader  {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background-color: #fefefe;
     z-index: 99998;
    height: 100%;
 }
.loader {
  width: 250px;
  height: auto;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #781007;
  letter-spacing: 0.2em;
  text-align: center;
}
.loader img {
    width: 100%;
    margin: auto;
}

img {
    max-width: 100%;
    height: auto;
}
h1.yrheading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #781007;
    margin-top: 20px;margin-bottom: 30px;
    padding-left: 15px
}
h1.yrheading:after {
    content: '';
    border-bottom: dashed 1px #781007;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 20px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
}

ul.album_list {
    float: left;
    width: 100%;
}
ul.album_list> li {
float: none;
    width: 33%;
    padding: 0 15px;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 20px;
    vertical-align: top;
}
ul.album_list> li:hover .album_box .item{ -webkit-box-shadow: 0 10px 25px -14px rgba(0, 0, 0, 0.3); box-shadow: 0 10px 25px -14px rgba(0, 0, 0, 0.3);}
.album_box {
    width: 100%;
    white-space: nowrap;
    float: left;
        overflow: hidden;
        max-width: 353px;
    padding-bottom: 5px;
    
}
.album_box .item {
    width: 100%;
    display: inline-block;
    border-radius: 0;
    background: #f5f5f5;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    white-space: normal;
    overflow: hidden;  
    max-width: 373px;
}
.album_box .item img {
    width: 100%;
    margin: auto;
}

.album_box .item label {
    display: block;
    font-size: 19px;
    margin: 5px 0;
    font-weight: 600;
    color: #781007;
    padding: 0 15px;
}   
.album_box .item p {
    margin-top: 0;margin-bottom: 10px;
    color: #353535;
    padding: 0 15px;font-size: 16px;
}
a.readmore {
    display: inline-block;
    padding: 8px 15px;
    background: #781007;
    text-transform: capitalize;
    color: #fff;
    border-radius: 3px;
}
a.readmore {
    display: inline-block;
    padding: 8px 15px;
    background: #781007;
    text-transform: capitalize;
    color: #fff;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    border-radius: 3px;
}
.album_box .item .readmore {
    margin-left: 15px;
    margin-bottom: 15px;
}
a.readmore:hover {
    background: #de4234;
}
/* .slick-lightbox-inner .slick-prev:after{content: url(../../images/left-arrow.png);}
.slick-lightbox-inner .slick-next:after{content: url(../../images/right-arrow.png);} */
.item.hdng_only label {
    min-height: 52px;
}
.album_box .item .imagBox {
    width: 100%;
    height: 235px;
    overflow: hidden;
    position: relative;
}
.album_box .item .imagBox img {
    width: auto;
    height: 100%;
    max-width: inherit;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    min-width: 100%;
}
.item ul.list {
    padding: 0 15px;
    padding-bottom: 10px;
}
.item ul.list li {
    width: 100%;
    margin-bottom: 5px;color: #353535;
}
.item ul.list li span {
    color: #781007;
}

#toTop {
    position: fixed;
    right: 20px;
    bottom:100px;
    display: none;
}
#toTop img {
    width: 40px;
    border-radius: 50%;
}

.desktopimg{display: block !important;}
.mobileimg{display: none !important;}
.right{float: right;}
.left{float: left;}
.cap_img {
    border: solid 4px #970902 !important;
    padding: 0 !important;
    display: block;
}
.cap_img.left {
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 5px;
}
ul.partntr_list {
    float: left;
    width: 100%;
}
ul.partntr_list li {
    float: left;
    width: 25%;
    padding: 15px;
    
}
.partners {
    background: #f2f0f0;
    width: 100%;
    float: left;
    padding-bottom: 50px;
}
.partners{
    background: #f2f0f0;
    float: left;
    width: 100%;
}
ul.partntr_list li a{
    background: #fff;
    display: block;
    height: 150px;
    padding: 5px 15px;
    position: relative;
    z-index: 9;
}
ul.partntr_list li a:after, ul.partntr_list li a:before {
    position: absolute;
    width: 30px;
    height: 30px;
    background: transparent;
    content: '';
    z-index: -1;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}
ul.partntr_list li a:before {
    bottom: -1px;
    left: -1px;
    border-bottom: solid 1px #961108;
    border-left: solid 1px #961108;
}
ul.partntr_list li a:after {
    right: -1px;
    top: -1px;
    border-right: solid 1px #961108;
    border-top: solid 1px #961108;
}
ul.partntr_list li a:hover:after,ul.partntr_list li a:hover:before{width: 106%;height: 110%;}




ul.partntr_list li a img{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 10px;
    max-height: 100%;
}
ul.partntr_list li a:hover img{-webkit-filter: grayscale(1);filter: grayscale(1);}
.partners #contenor {
    background: none;
}
ul.press_list {
    width: 100%;
    float: left;
    margin-bottom: 50px;
}
ul.press_list li {
    padding: 10px 0;
    border-bottom: dashed 1px #e7e7e7;    
    font-size: 15px;
    color: #585858;
    padding-left: 15px;
    border-left: 2px solid #9d1108;
    margin: 10px 0;    
    word-break: break-all;
}
ul.press_list li:last-of-type{border-bottom: 0;}
ul.press_list li a {
    color: #585858;
    text-decoration: none;
}
ul.press_list li a:hover {
    color: #781007;
}
ul.press_list li em {
    color: #000000;
}
ul.video_list {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}
ul.video_list li {
    width: 49%;
    display: inline-block;
    text-align: center;
    margin: 10px 0;
    padding: 0 10px;
    overflow: hidden;
}
ul.video_list li img{display: block;}
a.videoItem:after {
    position: absolute;
    content:'';
    background: url(../images/play-button.png);
    background-size: cover;
    height: 50px;
    width: 50px;
    left: 50%;
    top: 50%;
    z-index: 9;
    -webkit-transform:translate(-50%, -50%);
        -ms-transform:translate(-50%, -50%);
            transform:translate(-50%, -50%); 
}
a.videoItem:before {
    position: absolute;
    content: attr(title);
    width: 100%;
    background: rgba(0, 0, 0, 0.50);
    z-index: 99;
    left: 0;
    text-align: center;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #fff;
    text-transform: capitalize;
    bottom: -70px;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}
a.videoItem {
    position: relative;
    display: block;
}
.flashy-overlay .flashy-close {
    top: 10px !important;
    right: 10px !important;
}
ul.video_list li:hover a.videoItem:before{bottom: 0;}
.contct_form {
    background:#f9f9f9;
    padding: 20px 50px;
    width: 100%;
    float: left;
    text-align: left;
    margin-bottom: 50px;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}

.form_grp .form_element {
    width: 50%;
    float: left;
    position: relative;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.error-message {
    position: absolute;
    right: 13px;
    bottom: -13px;
    font-size: 12px;
}
.form_element {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;flex-wrap: wrap;
}
.contacterr {

    width: 100%;
    padding-left: 91px;
    font-size: 13px;
    color: #961108;

}


.form_element input {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;    
    height: 32px;
    line-height: 32px;
    padding: 0 5px;outline: none;   
     border-radius: 2px;
    border: solid 1px #c5c5c5;
}
.form_element.email textarea{
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 5px;
    outline: none;    
    border-radius: 2px;
    border: solid 1px #c5c5c5;
}
.form_element label {
    font-size: 16px;
    margin-right: 20px;
    width: 70px;
    text-align: left;
}
.form_grp {
    float: left;
    width: 100%;
}
.form_element.email {
    width: 100%;
}
.requared{color: red;}
button.post_cmnt {
    height: 32px;
    padding: 0 15px;
    background: #781007;
    border: 0;
    color: #fff;
    float: right;
    margin-right: 10px;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 10px;
}

.contct_form .left_frm {
    width: 100%;
    float: left;
    text-align:left; 
}

.contct_form .right_frm {
    width: 100%;
    float: none;   
}
.slick-lightbox-slick-img{max-height: inherit !important;}
.slick-lightbox-slick-item{position: relative;}
.slick-lightbox-slick-caption {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 18px;
}

.slick-lightbox-inner .slick-list {
    max-height: inherit;
}
.contct_form .left_frm a {color: #bd1507; }
header {
    width: 100%;
    float: left;
    transition: all .3s linear;
}
/*header.fixed{
    position: relative;
    top: -154px;
    transition: all .3s linear;
}*/
header.fixed ~ #wrapper #banner.contac {
    position: fixed;
    transition: all .3s linear;
    top: -1px;
    height: 75px;
}
/*.contentf2.srollFix {
    line-height: 30px;
    min-height: 800px;
    overflow: hidden;
}*/
#wrap {
    width: 100%;
    float: left;
    min-height: 750px;
}
header.fixed ~ #wrap {
    padding-top: 100px;
    width: 100%;
    float: left;
}

.album_box .item:nth-child(1) {
    display: block;
}
.album_box .item {
    display: none;
}

/* new css */
.slick-slide> div{
    margin-bottom: -6px;
    }
    .slick-dots li button::before {
        font-size: 9px;
        top: 5px;
    }
    .slick-dots li.slick-active button:before{color: #990000;}
    .cap_img img {
        display: block;
    }
    header.fixed~#wrapper #banner.contac ~ #wrap {
        padding-top: 100px;
        width: 100%;
        float: left;
    }
    button[disabled] {
        opacity: .6;
        cursor: not-allowed;
    }
    div#messagePoup {
        position: fixed;
        background: rgba(0,0,0,.5);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #messagePoup .popinner {
        width: 100%;
        max-width: 450px;
        background: #fff;
        position: relative;
        padding: 15px;
        border-radius: 5px;    
        z-index: 99;    
        padding-top: 30px;
        box-shadow: 0px 3px 15px 5px #3333336e;
    }
    .popinner a.close {
        position: absolute;
        right: 10px;
        top: 10px;cursor: pointer;
    }
    .popinner a.close svg {
        width: 15px;
        height: 15px;
    }
    #messagePoup .popinner h4{
        text-align: center;margin-bottom: 20px;
        font-size: 26px;
        color: #990000;;
    }
    .slick-prev{
        left: 10px;
        z-index: 22;
    }
    .slick-next{
        right: 10px;
        z-index: 22;
    }
    .slick-next:before, [dir=rtl] .slick-prev:before {
        content:url(../images/right-arrow.png);
    }
    .slick-prev:before {
        content:url(../images/left-arrow.png);
    }
    .slick-dots li{
margin: 0 1px;
    }

    .about_videos ul.video_list li a.videoItem::before {
        display: none;
    }





















    



















@media only screen and (min-width: 1200px) {
.flexslider .slick-slide img {height: 450px;width: 100%; }
#banner img {    
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50% , -50%);
    left: 50%;
}
#banner {
   max-height: 450px;
    position: relative;
    min-height: 410px;
}



}



@media only screen and (max-width: 1160px) {
#contenor{width: 100%;padding: 0 15px;}
#footer {
    padding: 15px 15px;
}
#footerinner {
   width: 100%;
}
.album_box .item .imagBox{height: 200px;}



}







@media only screen and (max-width: 1024px) {
#contenor{width: 100%;}
.footerinner{width: 100%;padding: 0 30px;}
.bannercontenor{width: 100%;text-align: center;}
#banner .bannerimg {
    width: 516px;
    margin-left: 0;
    margin: auto;
}

#footer {
    padding: 15px 15px;
}

#footerinner {
   width: inherit;
}
.slick-list{max-height: 400px;}

#banner{height: auto;}


} /*max width 1024px end here*/


@media only screen and (max-width: 998px) {
ul.album_list> li{width: 50%;float: left;}    
.album_box{float: none;margin: auto;}

#logo {
    float: none;
    margin: auto;
}
    
#navi {
    float: none;
    padding: 110px 0 0;
    text-align: center;
}

}


@media only screen and (max-width: 767px) {
    .about_videos ul.video_list li {
        width: 100%;
    }
    #logo{width: 200px;}
    .about_videos ul.video_list li a.videoItem {
        max-width: 500px;
        margin: auto;
    }    
.slick-lightbox .slick-prev{left: 0 !important;}
.slick-lightbox .slick-next{right: 0 !important;}
.submenu li{background: #a71509;}
.submenu li a.activelink {border-bottom-color: #fff; background: #080100a8; }
#toTop img{width: 30px;}
.slick-lightbox-slick-caption{display: none;}
#banner.contac .heading2{font-size: 18px;}
.contentf2.managers{text-align: justify;}
#banner {max-height: 320px; }
.contentf.homAbout {padding: 0; }
a.videoItem:before{bottom: 0;padding: 3px;
    font-size: 14px;z-index: 9;}
#header{overflow:visible; }
.menu-icon{display: block;}
ul.partntr_list li{width: 50%;}
.form_grp .form_element{width: 100%;}
.contct_form{padding: 20px;}
.heading2,.heading1{display: block; text-align: center;font-size: 22px;}
body{-webkit-overflow-scrolling: touch;}
#toTop{right: 3px;bottom: 20px;z-index: 92;}
#navi{   
    position: absolute;
    left: 0;
    background: #781007;
    z-index: 9999;
    width: 100%;padding-top: 0 !important;
    left: -0;
    max-height: 0;overflow: hidden;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    margin-top: 30px;
    -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);
    
}

.slick-lightbox-slick-item-inner{   
    vertical-align: middle !important;
    max-width: 90%;
    max-height: 100% !important;
    overflow: visible;
}


.contentf{padding: 0px 20px;}
#navi > ul> li> a:after{
    height: 0;display: none;
    background: none;
    border-bottom: dotted 1px #fff;
}
.slick-list{max-height: 300px;}
.has_sub .submenu {
    left: 0%;
    position:relative;
    width: 100%; 
    background: red;
    margin-top: 0;
    top: 0;display: block;
    padding-top: 0;
    max-height: 200px;
}
#navi li a.activelink {
    background: #3e0803;
}
.has_sub{padding-right: 0 !important}
.has_sub:after{top: 19px;border-top-color: #fff;}
#navi.active{max-height: 500px;}
#navi li {
    width: 100%;
    float: none;
    display: block;
    padding: 0;
    border-bottom: dotted 1px;
    border-right: 0;
}
#navi li a {
    text-decoration: none;
    color: #fff;
    display: block;
    border: 0;padding: 12px 15px; 
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}
#navi li:hover a{border-bottom: 0;background: #a93127;}



.submenu li:last-child {
    border-bottom: 0 !important;
}
.has_sub > a {
    margin-bottom: 2px;
}





}



@media only screen and (max-width: 620px) {

.desktopimg{display: none !important;}
.mobileimg{display: block !important;}
.cap_img{    
    float: none !important;
    margin: auto !important;
    max-width: 274px;
}
.cap_img img {
    width: 100%;
}
ul.video_list li{width: 100%;max-width: 350px;}
ul.partntr_list li{width: 100%;}
ul.partntr_list li a{max-width: 300px;margin: auto;}
}

@media only screen and (max-width: 680px) {
ul.album_list> li{width: 100%;}
ul.press_list li a{display: block;}

} /*max width 680px end here*/


@media only screen and (max-width: 480px) {
#g-recaptcha {
    transform: scale(0.8);
margin-left: -23px !important;
}
.contct_form .left_frm a{font-size: 13px;}
.slick-list{max-height: 300px;}
#footerinner{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
#copyright{    
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #ccc;
}
.form_grp .form_element{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.form_element input,.form_element.email textarea{width: 100%;}


} /*max width 480px end here*/





























































